body {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    line-height: 1.6;
    position: relative;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    background-color: #111!important;
}
.navbar{
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 75px;
	background-color: #00828c;
	color: white;
  position: fixed!important;
  width: 100%;
}
.logo{
	font-size: 30px;
  padding-left: 20px;
}
.nav-link{
	display: flex!important;
	justify-content: flex-end;
	list-style: none;
	width: 50%;
}
.nav-c{
	text-decoration: none;
	color: #fff;
	font-size: 20px;
	padding: 10px 20px;
	margin: 0 10px;
	cursor: pointer;
}
.mobile-menu-icon{
	display: none;
}
/* @media (max-width:974px){
.mobile-menu-icon{
		display: block;
		position: absolute;
		font-size: 30px;
		color: #fff;
		background-color: #00828c;
		border: none;
		outline: none;
		top: 15px;
		right: 25px;
	}
} */
@media (max-width:974px) {
	.log0{
		display: flex!important;
		position: absolute;
		top: 15px;
		left: 35px;
	}
	.nav-link{
		display: none!important;
	}
	.nav-link-mobile{
		position: absolute;
		display: block;
		list-style: none;
		background-color: #00828c;
		left: 0;
		top: 75px;
		transition: all 0.5s ease-out;
		        width: 32%;
	}
	.nav-c{
		color: #fff;
		text-align: center;
    display: block;
		padding: 32px;
		width: 100%;
		transition: all 0.5s ease;
	}
	.mobile-menu-icon{
		display: block;
		position: absolute;
		font-size: 30px;
		color: #fff;
		background-color: #00828c;
		border: none;
		outline: none;
		top: 15px;
		right: 25px;
	}
	
}
/* ============================ */
/* modal popup */
/* .modal-wrapper{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(189, 189, 189, 0.9);
}
.modalbox{
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%,-50%);
   min-width: 30rem;
   background-color: #fff;
   padding: 30px;
   border-radius: 10px;
}
.modal-btn{
  padding: 0.6rem 1.4rem;
  font-size: 1.4rem;
  border: none;
  border-radius: 0.3rem;
  background-color: #212121;
  color: #fff;
  cursor: pointer;
  font-family: "Work Sans",sans-serif;
  transform: scale(0.9);
}
.modal-btn:hover{
  background-color: #fff;
  color: #212121;
  border: #212121;
  transform: scale(1);

}
.close-icon {
    color: #000;
    cursor: pointer;
    font-size: 20px;
    position: fixed;
    left: 95%;
    top: 9px;
    
} */
.close-icon:hover{
  color: red;
}
/* .navbar {
    position: fixed!important;
    top: 0;
} */
img.logoimg {
       width: 136px;
    height: 76px;
		margin-top: -7px;
}
/* .nav-logo a{
  text-decoration: none;
}
.nav-logo h1{
  color: #fff;
  margin-top: -22px;
} */
.pb-34 {
        padding-bottom: 34px;
}
.banner img{
  width: 100%;
  height: 500px;
}
.line{
  padding-right: 20px;
}
#name-12 {
    font-size: 37px;
    line-height: 62px;
    font-weight: 700;
    margin: 18px 0 10px;
    position: relative;
    padding-left: 70px;
    color: #ffb400;
    text-align: center;
    z-index: -10;
}
#name-12 span {
    color: #fff;
    display: block;
    margin-left: -160px;
}
p.open-sans-font {
    margin: 15px 0 28px;
    font-size: 16px;
    color: #fff;
}
.profile ul li{
  color: #fff;
}
.title {
    opacity: .7;
    text-transform: capitalize;
}
.box-stats.with-margin {
    margin-bottom: 30px;
}
.box-stats {
    padding: 20px 30px 25px 40px;
    border-radius: 5px;
    border: 1px solid #252525;
}
.box-stats h3 {
    position: relative;
    display: inline-block;
    margin: 0;
    font-size: 50px;
    font-weight: 700;
    color: #ffb400;
}
.box-stats h3:after {
    content: '+';
    position: absolute;
    right: -24px;
    font-size: 33px;
    font-weight: 300;
    top: 2px;
}
.box-stats p {
    padding-left: 45px;
}
 .box-stats p:before {
    content: "";
    position: absolute;
    left: 0;
    top: 13px;
    width: 30px;
    height: 1px;
    background: #777;
}
.skills {
    padding-top: 60px;
        position: relative;
    z-index: -10;
}
.profile h2{
  color: #fff;
  padding-bottom: 20px;
  margin-top: -75px;
}
.profile {
    padding-top: 100px;
}
.title-bg {
    font-size: 89px;
    left: 17px;
    letter-spacing: 10px;
    line-height: 0.7;
    position: absolute;
    right: 0;
    top: 7%;
    text-transform: uppercase;
    font-weight: 800;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.07);
}
.portfolio {
    text-align: center;
    padding-top: 117px;
}
.portfolio h1{
  color: #fff;
  font-size: 37px;
    line-height: 62px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 30px;
}
.work{
  font-size: 89px;
    left: 17px;
    letter-spacing: 10px;
    line-height: 0.7;
    position: absolute;
    right: 0;
    top: 65%;
    text-transform: uppercase;
    font-weight: 800;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.07);
}
.workproject img{
  width: 100%;
}
.project1 {
    padding-bottom: 20px;
}



 /* Apply css properties to h1 element */
 hr.separator {
    border-top: 1px solid #716e6e;
    margin: 70px auto 55px;
    max-width: 40%;
}
  .ProgressBar {
    text-align: center;
}     
 .myskills {
    color: #fff;
    font-size: 37px;
    line-height: 62px;
    font-weight: 700;
}
.skillstitle{
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.pb-20{
  padding-bottom: 20px;
}
    .containerskill { 
            background-color: rgb(192, 192, 192); 
            width: 80%; 
            border-radius: 15px; 
        } 
  
        .skill { 
            background-color: #ffb400; 
            color: white; 
            padding: 1%; 
            text-align: right; 
            font-size: 20px; 
            border-radius: 15px; 
        } 
  
        .html { 
            width: 89%; 
        } 
        .css{
            width: 89%; 
        }
        .javascript{
            width: 70%; 
        }
        .wordpress{
            width: 60%; 
        }
        .jquery{
            width: 50%; 
        }
        .React{
            width: 40%; 
        }
  
        .php { 
            width: 30%; 
        } 
        .bootstrap{
          width: 80%;
        }
        .resume-box ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.resume-box li {
    position: relative;
    padding: 0 20px 0 60px;
    margin: 0 0 50px;
}
.resume-box .icon{
      background-color: #ffb400;
}
.resume-box .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    right: 0;
    line-height: 40px;
    text-align: center;
    z-index: 1;
    border-radius: 50%;
    color: #fff;
}
.resume-box .time {
    color: #fff;
    font-size: 12px;
    padding: 1px 10px;
    display: inline-block;
    margin-bottom: 12px;
    border-radius: 20px;
    font-weight: 600;
    background-color: #252525;
    opacity: .8;
}
.resume-box h5 {
    font-size: 18px;
    margin: 7px 0 10px;
    color: #eee;
}
.resume-box p {
    margin: 0;
    color: #eee;
    font-size: 14px;
    text-align: left;
}
.resume-box li:after {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    border-left: 1px solid #333;
}
svg.faicon {
    font-size: 25px;
    margin-top: -9px;
}
.about {
    padding-top: 100px;
}
.exp_edu {
    position: relative;
    z-index: -10;
}
.aboutme{
  text-align: center;
}
.aboutme h1{
  color: #fff;
    font-size: 37px;
    line-height: 62px;
    font-weight: 700;
    text-transform: uppercase;
}
.aboutme h1 span{
  color: #ffb400;
}
.pwork{
  padding-top: 80px;
}

/* .hover-box{
  position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    justify-content: center;
} */
.project1:hover img {
  opacity: 0.3;
 
}

.project1:hover .hover-box {
  opacity: 1;
}
.hover-text:hover{
  position: absolute;
  background-color: #ffb400;
}

.project1 {
  position: relative;
  /* width: 50%; */
}

/* .image {
  display: block;
  width: 100%;
  height: auto;
} */

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 93%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #ffb30066;
}

.project1:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
/* .workproject {
    position: relative;
    z-index: -10;
} */
div#box-hight1 {
    height: 150px;
}
.touch{
	color: #ffb400;
}
.get-in{
	font-size: 47px!important;
}
.contact-page{
	font-size: 89px;
    left: 17px;
    letter-spacing: 10px;
    line-height: 0.7;
    position: absolute;
    right: 0;
    top: 28%;
    text-transform: uppercase;
    font-weight: 800;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.07);

}
input.input-box {
    border: 1px solid #111;
    width: 100%;
    background: #252525;
    color: #fff;
    padding: 11px 26px;
    margin-bottom: 30px;
    border-radius: 30px;
    outline: none !important;
    transition: .3s;
}
.text-box{
 border: 1px solid #111;
    background: #252525;
    color: #fff;
    width: 100%;
    padding: 12px 26px;
    margin-bottom: 20px;
    height: 160px;
    overflow: hidden;
    border-radius: 30px;
    outline: none !important;
    transition: .3s;
}
.button {
    overflow: hidden;
    display: inline-block;
    line-height: 1.4;
    border-radius: 35px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
    transition: 250ms all ease-in-out;
    text-transform: uppercase;
    text-decoration: none !important;
    position: relative;
    z-index: 1;
    padding: 16px 70px 16px 35px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    background-color: transparent;
    outline: none !important;
        border: 1px solid #ffb400;
}
.button .button-text {
  position: relative;
  z-index: 2;
  color: #fff;
  transition: .3s ease-out all;
}

.button:before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(100%);
  transition: .3s ease-out all;
  background-color: #ffb400;
}

.button:hover .button-text {
  color: #ffffff;
}

.button:hover:before {
  transform: translateX(0);
}

.button-icon {
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: 0;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  color: #ffffff;
  border-radius: 50%;
}
.d-block{
  color: #ffb400a8;
}
.email{
  color: #fff;
}
.home{
  padding-top: 180px;
  padding-bottom: 50px;
}
.profile-conntant{
  padding-top: 83px;
}
.profile-img img{
  width: 74%;
  height: 400px;
  box-shadow: 0 9px 11px 0 #ffb400;
  border-radius: 5px;
}
.profile-img img:hover{
  box-shadow: 0 9px 11px 0 #00828c;
}
.workproject {
    position: absolute;
    z-index: -2;
    margin-left:7%;
}
.about-list li:not(:last-child) {
    padding-bottom: 20px;
}
@media (max-width:780px){
  .workproject {
    margin-left:0;
}
  .title-bg {
    top:5%;
  }
  .work{
    top: 145px;
  }
  .contact-page {
    top: 18%;
  }
}
@media (max-width:500px){
  .logo{
    font-size: 17px;
  }
  .profile-conntant {
    padding-top: 0;
}
#name-12 {
    font-size: 18px;
    padding-left: 0;
}
#name-12 span {
    font-size: 22px;
}
.profile-img img {
    margin-left: 39px;
}
    .nav-link-mobile {
        position: absolute;
        display: block;
        list-style: none;
        background-color: #00828c;
        left: 0;
        top: 75px;
        transition: all 0.5s ease-out;
        width: 64%;
    }
    .title-bg {
      font-size: 61px;
        top: 4%;
    }
    .d-block {
    color: #ffb400a8;
    font-size: 14px;
}

.box-stats p:before {
    left: -23px;
}
.box-stats p {
    padding-left: 18px;
}
.skillstitle {
    font-size: 10px;
    font-weight: 600;
}
    .work {
        top: 145px;
        font-size: 74px;
    }
        .contact-page {
        top: 16%;
        font-size: 55px;
    }
    a#top__logo {
    color: #fff;
    text-decoration: none;
}
}
@media (min-width:501px) and (max-width:565px){
  .logo{
    font-size: 22px;
  }
  .profile-conntant {
    padding-top: 0;
}
#name-12 {
    font-size: 27px;
    padding-left: 0;
}
#name-12 span {
    font-size: 22px;
}
.profile-img img {
    margin-left: 39px;
}
a#top__logo {
    color: #fff;
    text-decoration: none;
}
.contact-page {
    font-size: 75px;
}
}
.banner {
    padding-top: 49px;
}
.email-icon {
    font-size: 33px;
    color: #ffb400;
}
    a#top__logo {
        color: #fff;
        text-decoration: none;
    }